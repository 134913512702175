.post-item {
  background: var(--color-box-base);
  border: 1px solid var(--color-line-in-white);
  border-radius: 0.8rem;
  margin-top: 2.4rem;
  overflow: hidden;
}

.post-item header {
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.post-item header img {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
}

.post-item header div {
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.post-item header div strong {
  font: 700 1.5rem Archivo;
  display: block;
  color: var(--color-text-title);
}

.post-item header div span {
  font-size: 1.3rem;
  display: block;
  margin-top: 0.4rem;
}

.post-item > div {
  padding: 0 2rem;

  height: 3rem;
  overflow: hidden;
}

.post-item > div > ul {
  padding: 0 2rem;
}

.post-item > div > p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.post-item > div.expand > p {
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: none;
}

.post-item > div.expand {
  height: auto;
  overflow: auto;
}

.post-item > button,
.post-item > a {
  margin: 1rem 2rem;
  padding: 0rem 2rem;
  height: 3rem;
  background: var(--color-button-background);
  color: var(--color-secundary);
  border: 0;
  border-radius: 0.8rem;
  cursor: pointer;
  font: 700 1.4rem Archivo;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  transition: 0.2s;
  text-decoration: none;
  outline: none;
}

.post-item > button:hover,
.post-item > a:hover {
  color: var(--color-secundary-dark);
}

.post-item footer {
  padding: 2rem 2rem;
  background: var(--color-box-footer);
  border-top: 1px solid var(--color-line-in-white);
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.post-item footer span {
  color: var(--color-primary);
  font-size: 1.3rem;
  display: block;
  padding: 0 1rem;
  align-items: center;
  background: var(--color-secundary);
  color: var(--color-line-in-white);
  border: 0;
  border-radius: 1rem;
}

.post-item footer span.module {
  background: var(--color-secundary);
}

.post-item footer span.inactive {
  background: var(--color-black);
}

.post-item footer span.private {
  background: var(--color-black);
}

.post-item footer span.not-reviewed {
  background: var(--color-black);
}

.post-item footer span.type {
  background: var(--color-text-complement);
}

@media (min-width: 700px) {
  .post-item header,
  .post-item footer {
    padding: 2rem;
  }

  .post-item footer span {
    display: initial;
  }
}
