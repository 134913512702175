#page-post-list {
  width: 100vw;
  height: 100vh;
}

#search-posts {
  width: 100%;
}

#search-posts {
  margin-top: 3.2rem;
}

#search-posts label {
  color: var(--color-text-in-primary);
}

#search-posts button {
  width: 100%;
  height: 5.6rem;
  background: var(--color-secundary);
  color: var(--color-button-text);
  border: 0;
  border-radius: 0.8rem;
  cursor: pointer;
  font: 700 1.6rem Archivo;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: background-color 0.2s;
  margin-top: 3.2rem;
  outline:none;
}

#search-posts button:hover {
  background: var(--color-secundary-dark);
}

#page-post-list main {
  margin: 3.2rem auto;
  width: 90%;
}

#page-post-list main h2 {
  margin-top: 5rem;
}

@media (min-width: 700px) {
  #page-post-list {
    max-width: 100vw;
  }

  #search-posts {
    max-width: 100vw;
    column-gap: 16px;
    display: grid !important;
    bottom: -28px;
    position: absolute;
    grid-template-areas:
      'module submodule type'
      'search search button' !important;
    grid-template-columns: 1fr 1fr 1fr !important;
  }

  #moduleSelect {
    grid-area: module !important;
  }

  #submoduleSelect {
    grid-area: submodule !important;
  }

  #typeSelect {
    grid-area: type !important;
  }

  #searchInput {
    grid-area: search !important;
  }

  #searchButton {
    grid-area: button !important;
  }

  #search-posts .input-block,
  #search-posts .select-block {
    margin-top: 0;
  }

  #page-post-list main {
    padding: 3.2rem 0;
    max-width: 740px;
    margin: 0 auto;
  }
}
