.page-header {
  display: flex;
  flex-direction: column;
  background-color: var(--color-primary);
}

.page-header .top-bar-container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-text-in-primary);
  padding: 2rem 0;
}

.page-header .top-bar-container a {
  height: 3.2rem;
  width: 3.2rem;
  font-size: 3.2rem;
  transition: opacity 0.2s;
  color: var(--color-text-in-primary);
}

.page-header .top-bar-container a:hover {
  opacity: 0.6;
}

.page-header .top-bar-container > button.login {
  height: 3.2rem;
  width: 10rem;
  background: var(--color-secundary);
  color: var(--color-button-text);
  border: 0;
  border-radius: 0.8rem;
  cursor: pointer;
  font: 700 1.6rem Archivo;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: background-color 0.2s;
  outline: none;
}

.page-header .top-bar-container > button.login:hover {
  background: var(--color-secundary-dark) !important;
}

.page-header .top-bar-container .inline {
  display: flex;
  align-items: center;
}

.page-header .top-bar-container .inline span {
  margin: 0 1rem;
  font-size: 2rem;
}

.page-header .header-content {
  width: 90%;
  margin: 0 auto;
  position: relative;
  margin: 3.2rem auto;
}

.page-header .header-content strong {
  margin-top: -15rem;
  font: 700 3.6rem Archivo;
  line-height: 4.2rem;
  color: var(--color-title-in-primary);
}

.page-header .header-content p {
  max-width: 30rem;
  font-size: 1.6rem;
  line-height: 2.6rem;
  color: var(--color-text-in-primary);
  margin-top: 2.4rem;
}

fieldset {
  border: 0;
}

fieldset legend {
  margin-top: 1.6rem;
  font: 700 2.4rem Archivo;
  color: var(--color-text-title);
  margin-bottom: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 1.6rem;
  border-bottom: 1px solid var(--color-line-in-white);
}

fieldset legend button {
  background: none;
  border: 0;
  color: var(--color-primary);
  font: 700 1.6rem Archivo;
  cursor: pointer;
  transition: color 0.2s;
}

fieldset legend button:hover {
  color: var(--color-primary-dark);
}

footer {
  border-top: 1px solid var(--color-line-in-white);
  margin-top: 5.4rem;
}

footer button {
  width: 100%;
  height: 5.6rem;
  background: var(--color-secundary);
  color: var(--color-button-text);
  border: 0;
  border-radius: 0.8rem;
  cursor: pointer;
  font: 700 1.6rem Archivo;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: background-color 0.2s;
  margin-top: 3.2rem;
}

footer button:hover {
  background: var(--color-secundary-dark);
}

@media (min-width: 700px) {
  .page-header {
    height: 350px;
  }

  .page-header .top-bar-container {
    max-width: 740px;
  }

  .page-header .header-content {
    flex: 1;
    max-width: 740px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .page-header .header-content strong {
    max-width: 700px;
  }
}
