.textarea-block {
  position: relative;
}

.textarea-block label {
  font-size: 1.4rem;
}

.textarea-block .jodit-container {
  width: 100%;
  margin-top: 0.8rem;
  border-radius: 0.8rem;
  border: 1px solid var(--color-line-in-white);
}

.textarea-block ul {
  padding: 0 2rem;
}

.textarea-block:focus-within::after {
  width: calc(100% - 3.2rem);
  height: 2px;
  content: '';
  background: var(--color-primary-light);
  position: absolute;
  left: 1.6rem;
  right: 1.6rem;
  bottom: 0px;
}
