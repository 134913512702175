.select-block {
  position: relative;
}

.select-block + .select-block {
  margin-top: 1.4rem;
}

.input-block + .select-block {
  margin-top: 1.4rem;
}

.select-label-container {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.select-label-container label {
  font-size: 1.4rem;
}

.select-block button {
  font-size: 1.4rem;
  border: 0;
  color: var(--color-secundary);
  background-color: transparent;
  cursor: pointer;
}

.select-block button:hover {
  color: var(--color-secundary-dark);
}

.select-block select {
  width: 100%;
  height: 5.6rem;
  margin-top: 0.8rem;
  border-radius: 0.8rem;
  background: var(--color-input-background);
  border: 1px solid var(--color-line-in-white);
  outline: 0;
  padding: 0 1.6rem;
  font: 1.6rem Archivo;
}

.select-block:focus-within::after {
  width: calc(100% - 3.2rem);
  height: 2px;
  content: '';
  background: var(--color-primary-light);
  position: absolute;
  left: 1.6rem;
  right: 1.6rem;
  bottom: 0;
}
