:root {
  font-size: 60%;

  --color-background: #f0f0f7;
  --color-primary-lighter: #ffaf49;
  --color-primary-light: #ff9e22;
  --color-primary: #fa8100;
  --color-primary-dark: #f47100;
  --color-primary-darker: #ee6002;
  --color-secundary: #e65100;
  --color-secundary-dark: #d65100;
  --color-title-in-primary: #ffffff;
  --color-text-in-primary: #ffddb0;
  --color-text-title: #e54304;
  --color-text-complement: #9c98a6;
  --color-text-base: #6a6180;
  --color-line-in-white: #e6e6f0;
  --color-input-background: #f8f8fc;
  --color-button-background: #efefef;
  --color-button-text: #ffffff;
  --color-box-base: #ffffff;
  --color-box-footer: #fafafc;
  --color-black: #000000;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100vh;
  overflow-x: hidden;
}

body {
  background: var(--color-background);
}

#root {
  display: flex;
  align-items: center;
  justify-content: center;
}

body,
input,
button,
textarea {
  font: 500 1.6rem Poppins;
  color: var(--color-text-base);
}

.container {
  width: 90vw;
  max-width: 700px;
}

td {
  border: 1px solid var(--color-line-in-white);
}

table {
  border-collapse: collapse;
}

@media (min-width: 700px) {
  :root {
    font-size: 62.5%;
  }
}

.capitalize {
  text-transform: capitalize !important;
}
