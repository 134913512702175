#page-post-form {
  width: 100vw;
  height: 100vh;
}

#page-post-form .page-header .header-content {
  margin-bottom: 6.4rem;
}

#page-post-form main {
  background: var(--color-box-base);
  width: 100%;
  max-width: 74rem;
  border-radius: 0.8rem;
  margin: -3.2rem auto 3.2rem;
  padding-top: 6.4rem;
  overflow: hidden;
}

#page-post-form main fieldset {
  border: 0;
  padding: 0 2.4rem;
}

#page-post-form main fieldset legend {
  font: 700 2.4rem Archivo;
  color: var(--color-text-title);
  margin-bottom: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 1.6rem;
  border-bottom: 1px solid var(--color-line-in-white);
}

#page-post-form main fieldset legend button {
  background: none;
  border: 0;
  color: var(--color-primary);
  font: 700 1.6rem Archivo;
  cursor: pointer;
  transition: color 0.2s;
}

#page-post-form main fieldset legend button:hover {
  color: var(--color-primary-dark);
}

#page-post-form main fieldset .input-block + .textarea-block,
#page-post-form main fieldset .textarea-block + .select-block,
#page-post-form main fieldset .select-block + .select-block,
#page-post-form main fieldset .select-block + .textarea-block,
#page-post-form main fieldset .select-block + .input-block {
  margin-top: 2.4rem;
}

#page-post-form main label {
  color: var(--color-text-complement);
}

#page-post-form main footer {
  padding: 4rem 2.4rem;
  background: var(--color-box-footer);
  border-top: 1px solid var(--color-line-in-white);
  margin-top: 6.4rem;
}

#page-post-form main footer p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: var(--color-text-complement);
}

#page-post-form main footer p img {
  margin-right: 2rem;
}

#page-post-form main footer button {
  width: 100%;
  height: 5.6rem;
  background: var(--color-secundary);
  color: var(--color-button-text);
  border: 0;
  border-radius: 0.8rem;
  cursor: pointer;
  font: 700 1.6rem Archivo;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: background-color 0.2s;
  margin-top: 3.2rem;
}

#page-post-form main footer button:hover {
  background: var(--color-secundary-dark);
}

@media (min-width: 700px) {
  #page-post-form {
    max-width: 100vw;
  }

  #page-post-form .page-header .header-content {
    margin-bottom: 0;
  }

  #page-post-form main {
    margin-top: -15rem;
  }

  #page-post-form main fieldset {
    padding: 0 6.4rem;
  }

  #page-post-form main footer {
    padding: 4.0rem 6.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  #page-post-form main footer p {
    justify-content: space-between;
  }

  #page-post-form main footer button {
    width: 20rem;
    margin-top: 0;
  }
}